import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import Cookies from "universal-cookie";

import "react-toastify/dist/ReactToastify.css";
import "./style.css";
// Context
import StateContext from "./context/StateContext";
import DispatchContext from "./context/DispatchContext";

// Components
import LandingPage from "./pages/LandingPage";
import AdminLogin from "./admin/pages/Login";
import CheckPointLogin from "./checkpoint/pages/Login";
import EntryPointLogin from "./entrypoint/pages/Login";
import Loader from "./components/Loader";
import ourReducer, { initialState } from "./Reducer/AppReducer";

const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Verify = lazy(() => import("./pages/Verify"));
const Info = lazy(() => import("./pages/Info/Info"));
const InfoDetail = lazy(() => import("./pages/Info/InfoDetail"));
const InfoAbout = lazy(() => import("./pages/Info/InfoAbout"));
const InfoCommingSoon = lazy(() => import("./pages/Info/InfoCommingSoon"));

const CardInventory = lazy(() =>
  import("./admin/pages/CardInventory/CardInventory")
);
// Visitors
const VisitorDashboard = lazy(() => import("./pages/visitors/Visitors"));
// CheckValidCard
const CheckValidCard = lazy(() =>
  import("./pages/checkValidCard/CheckValidCard")
);
// Registration
const RegistrationDashboard = lazy(() =>
  import("./registration/pages/Dashboard")
);
const RegistrationForm = lazy(() =>
  import("./registration/pages/RegistrationForm")
);
const SuccessMessage = lazy(() =>
  import("./registration/pages/SuccessMessage")
);
const GuideRegistration = lazy(() =>
  import("./registration/pages/GuideRegistration")
);
// Admin
const NFC = lazy(() => import("./admin/pages/NFC/NFC"));
const DocumentDetail = lazy(() =>
  import("./admin/pages/Document/DocumentDetail")
);
const AdminDashboard = lazy(() => import("./admin/pages/Dashboard"));
const AdminDashboardMain = lazy(() => import("./admin/pages/DashboardMain"));
const CheckPointEntryDetail = lazy(() =>
  import("./admin/pages/Checkpoint/CheckPointEntryDetail")
);
const CheckPointCreateUser = lazy(() =>
  import("./admin/pages/Checkpoint/CheckPointCreateUser")
);
const UserDetail = lazy(() => import("./admin/pages/User/UserDetail"));
const AdminCheckPoint = lazy(() =>
  import("./admin/pages/Checkpoint/CheckPoint")
);
const TotalInformationDetail = lazy(() =>
  import("./admin/components/TotalInformationDetail")
);
const ViewDetail = lazy(() => import("./admin/pages/ViewDetail"));
const Trail = lazy(() => import("./admin/pages/Trail/Trail"));
const TrailCreate = lazy(() => import("./admin/pages/Trail/TrailCreate"));
const EditTrail = lazy(() => import("./admin/pages/Trail/EditTrail"));
const Guide = lazy(() => import("./admin/pages/Guide/Guide"));
const EditGuide = lazy(() => import("./admin/pages/Guide/EditGuide"));
const CreateUser = lazy(() => import("./admin/pages/User/CreateUser"));
const EditUser = lazy(() => import("./admin/pages/User/EditUser"));
const Roles = lazy(() => import("./admin/pages/Role/Roles"));
const RoleEdit = lazy(() => import("./admin/pages/Role/RoleEdit"));
const RoleCreate = lazy(() => import("./admin/pages/Role/RoleCreate"));
const VisitorDetail = lazy(() => import("./admin/pages/VisitorDetails"));
const CheckPointEditUser = lazy(() =>
  import("./admin/pages/Checkpoint/CheckPointEditUser")
);
const AdminEntryPoint = lazy(() =>
  import("./admin/pages/Entrypoint/EntryPoint")
);
const EntryPointCreateUser = lazy(() =>
  import("./admin/pages/Entrypoint/EntryPointCreateUser")
);
const EntryPointEditUser = lazy(() =>
  import("./admin/pages/Entrypoint/EntryPointEditUser")
);
const EntryPointEntryDetail = lazy(() =>
  import("./admin/pages/Entrypoint/EntryPointEntryDetail")
);

const Document = lazy(() => import("./admin/pages/Document/Document"));
const AdminSettings = lazy(() => import("./admin/pages/Settings"));
const Filter = lazy(() => import("./admin/pages/Filter/Filter"));

// Checkpoint
const CheckPointHome = lazy(() => import("./checkpoint/pages/Home"));
const CheckPointMainDashboard = lazy(() =>
  import("./checkpoint/layout/MainDashboard")
);
const Details = lazy(() => import("./checkpoint/pages/Details"));
const CheckPointDashboard = lazy(() => import("./checkpoint/pages/Dashboard"));
const CheckPointSettings = lazy(() => import("./checkpoint/pages/Settings"));
// Entrypoint
const EntryPointDashboard = lazy(() => import("./entrypoint/pages/Dashboard"));
const EntryWelcome = lazy(() => import("./entrypoint/components/Welcome"));
const ScanForm = lazy(() => import("./entrypoint/components/ScanForm"));
const ManualForm = lazy(() => import("./entrypoint/components/ManualForm"));
const SubmissionForm = lazy(() =>
  import("./entrypoint/components/SubmissionForm")
);
const GuideForm = lazy(() => import("./entrypoint/components/GuideForm"));
const EntryPointSettings = lazy(() => import("./entrypoint/pages/Settings"));

const cookies = new Cookies();
function App() {
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (state.logoutCount > 0) {
      console.log("logoutCount");
      cookies.remove("trekcard_token", { path: "/" });
      cookies.remove("trekcard_user", { path: "/" });
      cookies.remove("trekcard_location", { path: "/" });
      cookies.remove("trekcard_location_id", { path: "/" });
      localStorage.removeItem("trekcard_role");
      cookies.remove("trekcard_permissions", { path: "/" });
      cookies.remove("filterDay", { path: "/" });
      localStorage.removeItem("checkPointName");
    }
  }, [state.logoutCount]);

  useEffect(() => {
    if (state.userLoggedIn && !cookies.get("trekcard_token")) {
      cookies.set("trekcard_token", state.credentials.token.trekcard_token, {
        path: "/",
        // maxAge: 43200,
      });
      cookies.set("trekcard_user", state.credentials.token.trekcard_user, {
        path: "/",
        // maxAge: 43200,
      });
      cookies.set(
        "trekcard_location",
        state.credentials.token.trekcard_location,
        {
          path: "/",
          // maxAge: 43200,
        }
      );
      localStorage.setItem(
        "trekcard_role",
        state.credentials.token.trekcard_role
      );
      localStorage.setItem("checkPointName", state.currentCheckPoint.name);

      if (state.credentials.token.trekcard_location === "checkpoint") {
        cookies.set(
          "trekcard_permissions",
          state.credentials.token.trekcard_permissions,
          {
            path: "/",
            // maxAge: 43200,
          }
        );
      } else {
        cookies.set(
          "trekcard_permissions",
          state.credentials.token.trekcard_permissions,
          {
            path: "/",
            // maxAge: 43200,
          }
        );
      }
    }
  }, [state.userLoggedIn]);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="admin" element={<AdminLogin />} />
            <Route path="checkpoint" element={<CheckPointLogin />} />
            <Route path="entrypoint" element={<EntryPointLogin />} />
            <Route
              path="forgot-password"
              element={
                <Suspense fallback={<Loader />}>
                  <ForgotPassword />
                </Suspense>
              }
            />
            <Route
              path="/password/reset"
              element={
                <Suspense fallback={<Loader />}>
                  <Verify />
                </Suspense>
              }
            />
            <Route
              path="visitors"
              element={
                <Suspense fallback={<Loader />}>
                  <VisitorDashboard />
                </Suspense>
              }
            />
            <Route
              path="checkValidCard"
              element={
                <Suspense fallback={<Loader />}>
                  <CheckValidCard />
                </Suspense>
              }
            />
            <Route
              path="admin/dashboard"
              element={
                <Suspense fallback={<Loader />}>
                  <AdminDashboard />
                </Suspense>
              }
            >
              {(state.credentials.token?.trekcard_permissions?.includes(25) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <>
                  <Route
                    index
                    element={
                      <Suspense fallback={<Loader />}>
                        <AdminDashboardMain />
                      </Suspense>
                    }
                  />
                  <Route
                    path="viewDetail/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <ViewDetail />
                      </Suspense>
                    }
                  />
                  <Route
                    path="totalInformationList/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <TotalInformationDetail />
                      </Suspense>
                    }
                  />
                </>
              )}
              {state.credentials.token.trekcard_role === "super_admin" && (
                <>
                  <Route
                    path="nfc"
                    element={
                      <Suspense fallback={<Loader />}>
                        <NFC />
                      </Suspense>
                    }
                  />
                  <Route
                    path="card-inventory"
                    element={
                      <Suspense fallback={<Loader />}>
                        <CardInventory />
                      </Suspense>
                    }
                  />
                  <Route
                    path="filter"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Filter />
                      </Suspense>
                    }
                  />
                </>
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(9) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <>
                  <Route
                    path="checkpoint"
                    element={
                      <Suspense fallback={<Loader />}>
                        <AdminCheckPoint />
                      </Suspense>
                    }
                  />
                  <Route
                    path="checkpoint/checkpointentrydetail/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <CheckPointEntryDetail />
                      </Suspense>
                    }
                  />
                  <Route
                    path="checkpoint/visitor-detail/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <VisitorDetail />
                      </Suspense>
                    }
                  />
                </>
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(11) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <Route
                  path="checkpoint/:id"
                  element={
                    <Suspense fallback={<Loader />}>
                      <CheckPointEditUser />
                    </Suspense>
                  }
                />
              )}

              {(state.credentials.token?.trekcard_permissions?.includes(10) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <Route
                  path="checkpoint/checkpointcreateuser"
                  element={
                    <Suspense fallback={<Loader />}>
                      <CheckPointCreateUser />
                    </Suspense>
                  }
                />
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(34) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <>
                  <Route
                    path="entrypoint"
                    element={
                      <Suspense fallback={<Loader />}>
                        <AdminEntryPoint />
                      </Suspense>
                    }
                  />
                  <Route
                    path="entrypoint/entrypointregistration/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <EntryPointEntryDetail />
                      </Suspense>
                    }
                  />
                  <Route
                    path="entrypoint/visitor-detail/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <VisitorDetail />
                      </Suspense>
                    }
                  />
                </>
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(35) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <Route
                  path="entrypoint/entrypointcreateuser"
                  element={
                    <Suspense fallback={<Loader />}>
                      <EntryPointCreateUser />
                    </Suspense>
                  }
                />
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(36) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <Route
                  path="entrypoint/:id"
                  element={
                    <Suspense fallback={<Loader />}>
                      <EntryPointEditUser />
                    </Suspense>
                  }
                />
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(14) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <>
                  <Route
                    path="trail"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Trail />
                      </Suspense>
                    }
                  />
                  <Route
                    path="trail/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <EditTrail />
                      </Suspense>
                    }
                  />
                </>
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(15) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <Route
                  path="create-trail"
                  element={
                    <Suspense fallback={<Loader />}>
                      <TrailCreate />
                    </Suspense>
                  }
                />
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(16) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <Route
                  path="edit-trail/:id"
                  element={
                    <Suspense fallback={<Loader />}>
                      <EditTrail />
                    </Suspense>
                  }
                />
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(27) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <>
                  <Route
                    path="guide"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Guide />
                      </Suspense>
                    }
                  />
                  <Route
                    path="guide/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <EditGuide />
                      </Suspense>
                    }
                  />
                </>
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(29) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <Route
                  path="edit-guide/:id"
                  element={
                    <Suspense fallback={<Loader />}>
                      <EditGuide />
                    </Suspense>
                  }
                />
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(5) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <>
                  <Route
                    path="user"
                    element={
                      <Suspense fallback={<Loader />}>
                        <UserDetail />
                      </Suspense>
                    }
                  />
                  <Route
                    path="user/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <EditUser />
                      </Suspense>
                    }
                  />
                </>
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(6) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <Route
                  path="user/create"
                  element={
                    <Suspense fallback={<Loader />}>
                      <CreateUser />
                    </Suspense>
                  }
                />
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(7) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <Route
                  path="user/edit/:id"
                  element={
                    <Suspense fallback={<Loader />}>
                      <EditUser />
                    </Suspense>
                  }
                />
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(1) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <>
                  <Route
                    path="roles"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Roles />
                      </Suspense>
                    }
                  />
                  <Route
                    path="roles/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <RoleEdit />
                      </Suspense>
                    }
                  />
                </>
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(2) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <Route
                  path="roles/create"
                  element={
                    <Suspense fallback={<Loader />}>
                      <RoleCreate />
                    </Suspense>
                  }
                />
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(3) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <Route
                  path="roles/edit/:id"
                  element={
                    <Suspense fallback={<Loader />}>
                      <RoleEdit />
                    </Suspense>
                  }
                />
              )}
              {(state.credentials.token?.trekcard_permissions?.includes(24) ||
                state.credentials.token.trekcard_role === "super_admin") && (
                <>
                  <Route
                    path="document/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <Document />
                      </Suspense>
                    }
                  />
                  <Route
                    path="document/visitor-detail/:id"
                    element={
                      <Suspense fallback={<Loader />}>
                        <DocumentDetail />
                      </Suspense>
                    }
                  />
                </>
              )}
              <Route
                path="settings"
                element={
                  <Suspense fallback={<Loader />}>
                    <AdminSettings />
                  </Suspense>
                }
              />
            </Route>
            {/* Checkpoint */}
            <Route
              path={
                localStorage.getItem("checkPointName") === "Check Out"
                  ? `checkpoint-check-out/:id`
                  : `checkpoint-check-in/:id`
              }
              element={
                <Suspense fallback={<Loader />}>
                  <CheckPointHome />
                </Suspense>
              }
            />
            <Route
              path="checkpoint/dashboard/:checkPointId"
              element={
                <Suspense fallback={<Loader />}>
                  <CheckPointDashboard />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<Loader />}>
                    <CheckPointMainDashboard />
                  </Suspense>
                }
              />
              <Route
                path="details/:userId"
                element={
                  <Suspense fallback={<Loader />}>
                    <Details />
                  </Suspense>
                }
              />
              <Route
                path="settings"
                element={
                  <Suspense fallback={<Loader />}>
                    <CheckPointSettings />
                  </Suspense>
                }
              />
            </Route>
            {/* Entrypoint */}
            <Route
              path="entrypoint/dashboard"
              element={
                <Suspense fallback={<Loader />}>
                  <EntryPointDashboard />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<Loader />}>
                    <EntryWelcome />
                  </Suspense>
                }
              />
              <Route
                path="manual-form"
                element={
                  <Suspense fallback={<Loader />}>
                    <ManualForm />
                  </Suspense>
                }
              />
              <Route
                path="scan-form"
                element={
                  <Suspense fallback={<Loader />}>
                    <ScanForm />
                  </Suspense>
                }
              />
              <Route
                path="submission-form"
                element={
                  <Suspense fallback={<Loader />}>
                    <SubmissionForm />
                  </Suspense>
                }
              />
              <Route
                path="guide-form/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <GuideForm />
                  </Suspense>
                }
              />
              <Route
                path="settings"
                element={
                  <Suspense fallback={<Loader />}>
                    <EntryPointSettings />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="info/*"
              element={
                <Suspense fallback={<Loader />}>
                  <Info />
                </Suspense>
              }
            />
            <Route
              path="info/detail"
              element={
                <Suspense fallback={<Loader />}>
                  <InfoDetail />
                </Suspense>
              }
            />
            <Route
              path="info/about"
              element={
                <Suspense fallback={<Loader />}>
                  <InfoAbout />
                </Suspense>
              }
            />
            <Route
              path="info/hotel"
              element={
                <Suspense fallback={<Loader />}>
                  <InfoCommingSoon />
                </Suspense>
              }
            />
            <Route
              path="info/health"
              element={
                <Suspense fallback={<Loader />}>
                  <InfoCommingSoon />
                </Suspense>
              }
            />
            <Route
              path="registration"
              element={
                <Suspense fallback={<Loader />}>
                  <RegistrationDashboard />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<Loader />}>
                    <RegistrationForm />
                  </Suspense>
                }
              />
              <Route
                path="message"
                element={
                  <Suspense fallback={<Loader />}>
                    <SuccessMessage />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="guideRegistration"
              element={
                <Suspense fallback={<Loader />}>
                  <RegistrationDashboard />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<Loader />}>
                    <GuideRegistration />
                  </Suspense>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export default App;
